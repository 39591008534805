.app.error-page .card,
.app.lockscreen .card {
    display: block;
    width: 100%;
    margin: 100px auto;
}

.app.lockscreen .card {
    max-width: 400px;
}

.form-layout {
    @include flex(none);
    width: 100%;
    margin: 0 auto;

    label {
        display: block;
        width: 100%;
    }

    .divider {
        display: block;
        height: 1px;
        margin-top: 1.563rem;
        margin-right: -1rem;
        margin-bottom: 1.563rem;
        margin-left: -1rem;
        text-align: center;
        background: rgba(0, 0, 0, 0.1);
    }

    .divider span {
        position: relative;
        top: -10px;
        display: inline-block;
        padding: 0 .75rem;
        background-color: white;
    }
}
